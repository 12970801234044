//@import '../../node_modules/bootstrap/dist/css/bootstrap-grid.min.css';

@import "mixins";
@import "custom";
@import "variables";
@import "layout";
@import "normalize";

#MainContainer {
  max-width: none;
  padding: 0;
}
#ms-designer-ribbon {
  display: none;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}
body {
  zoom: 1 !important;
}

// html {
//     @media (max-width: 1920px) {
//         font-size: calc(0.835vw + 0.0073px);
//         // font-size: calc(13.5px + (16 - 13.5) * ((100vw - 1024px) / (1920 - 1024)));
//     }
//     @media screen and (max-width: 739.9px) {
//         // font-size: calc(4.1007vw + 0.0073px);
//         font-size: calc(4.26vw + 0.0073px);
//     }
//     // @media (max-width: 1023px) {
//     // 	font-size: 13.5px;
//     // }
// }
// html.zoom {
//     @media (max-width: 1920px) {
//         font-size: calc(1.135vw + 0.0073px);
//         // font-size: calc(13.5px + (16 - 13.5) * ((100vw - 1024px) / (1920 - 1024)));
//     }
//     @media screen and (max-width: 739.9px) {
//         // font-size: calc(4.1007vw + 0.0073px);
//         font-size: calc(4.75vw + 0.0073px);
//     }
// }
// body {
//     margin: 0;
//     padding: 0;
//     height: 100vh;
//     overflow-y: auto;
//     background-color: #e6ba64;
// }
// img {
//     width: 100%;
//     height: auto;
// }
.pixel.zoom {
  @media (max-width: 1919px) {
    // font-size: calc(1.135vw + 0.0073px);
    font-size: calc(0.835vw + 0.0073px);
    // font-size: calc(13.5px + (16 - 13.5) * ((100vw - 1024px) / (1920 - 1024)));
  }
  @media screen and (max-width: 739.9px) {
    // font-size: calc(4.1007vw + 0.0073px);
    // font-size: calc(4.75vw + 0.0073px);
    font-size: calc(4.0695vw + 0.0073px);
  }
}
.pixel {
  opacity: 0;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  font-family: "Kaspersky", Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  // height: calc(100vh - 85px);
  overflow: visible;
  background-color: #e6ba64;
  transition: 0.5s;
  &.loaded {
    opacity: 1;
  }
  @media (min-width: 1920px) {
    font-size: 16px;
  }
  @media (max-width: 1919px) {
    font-size: calc(0.835vw + 0.0073px);
    // font-size: calc(13.5px + (16 - 13.5) * ((100vw - 1024px) / (1920 - 1024)));
  }
  @media screen and (max-width: 739.9px) {
    // font-size: calc(4.1007vw + 0.0073px);
    // font-size: calc(4.26vw + 0.0073px);
    font-size: calc(4.0695vw + 0.0073px);
    // font-size: calc( 2.3810vw + 2.38px );
  }

  .main {
    height: 100%;
    .container {
      width: 100%;
      max-width: 1920px;
      margin: 0 auto;
    }
  }
  .main.fixed-height {
    height: 100% !important;
  }
  .backgrounds {
    z-index: -1;
    position: relative;
    // height: calc(100vh - 85px);
    height: 100vh;
    overflow: hidden;

    .background {
      position: fixed;
      opacity: 0;
      // visibility: hidden;
      // height: calc(100vh - 85px);
      height: 100vh;
      width: 100%;
      display: flex;
      // top: 85px;
      top: 0;
      left: 0;

      &__img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        @media screen and (max-width: 739.9px) {
          background-size: contain;
          width: 100%;
          height: 100%;
        }
      }
      img {
        // width: 100%;
        // height: 100%;
      }

      &:nth-child(1) {
        opacity: 1;
        // color: #fdf8c8;
      }
      // &:nth-child(2) {
      //     background-color: var(--color-bg-2);
      // }
      // &:nth-child(3) {
      //     background-color: var(--color-bg-3);
      // }
      // &:nth-child(4) {
      //     // position: absolute;
      //     height: 100%;
      // }

      // &:not(:first-child) {
      //     top: 100%;
      // }
      &__birds-img {
        z-index: 1;
        position: absolute;
        top: 15%;
        opacity: 1;
        visibility: visible;
        width: 100vw;
        @media screen and (max-width: 739.9px) {
          // height: 100px;
          min-width: 64em;
        }
      }
      &__birds-img.bottom {
        z-index: 122;
        position: absolute;
        top: auto;
        display: block;
        bottom: 15%;
        transform: translateX(0);
        transform: rotate(180deg);
        animation-duration: 3s;
        animation-name: show-birds;
        @media screen and (max-width: 739.9px) {
          bottom: 12%;
        }
      }
      @keyframes show-birds {
        0% {
          display: none;
          //    transform: translateX(100%);
          // background: linear-gradient(76deg, #73f 0%, #d3f 101.05%);
        }

        100% {
          display: block;
          // transform: translateX(0);
          // background: linear-gradient(34deg, #2ee5c7 47.61%, #5f3 114.66%);
        }
      }
      // animation-duration: 1s;
      // animation-name: hide-bg;
    }

    @keyframes hide-bg {
      0% {
        opacity: 1;
        // background: linear-gradient(76deg, #73f 0%, #d3f 101.05%);
      }

      100% {
        opacity: 0;
        // background: linear-gradient(34deg, #2ee5c7 47.61%, #5f3 114.66%);
      }
    }

    .background.active {
      position: fixed;
      opacity: 1;
      visibility: visible;

      animation-duration: 1s;
      animation-name: show-bg;
    }

    @keyframes show-bg {
      0% {
        opacity: 0;
        // background: linear-gradient(76deg, #73f 0%, #d3f 101.05%);
      }

      100% {
        opacity: 1;
        // background: linear-gradient(34deg, #2ee5c7 47.61%, #5f3 114.66%);
      }
    }
  }

  .screens {
    z-index: 2;
    position: relative;
    width: 100%;
    // height: calc(100vh - 85px);
    overflow: hidden;

    .screen {
      position: fixed;
      // opacity: 0;
      // visibility: hidden;
      // height: calc(100vh - 85px);
      height: 100vh;
      width: 100%;
      // top: 85px;
      top: 0;
      left: 0;

      img {
        // width: 100%;
        // height: 100%;
      }
      &__img {
        opacity: 0;
        visibility: hidden;

        // animation-duration: 1s;
        // animation-name: hide-bg;
      }
      &__content {
        z-index: 1;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }

      &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2.5em;
        width: 46.875em;
        height: 5.9375em;
        @media screen and (max-width: 739.9px) {
          width: 20.875em;
          height: 0.9375em;
          margin-bottom: 2.5em;
        }
      }

      &__description {
        margin-bottom: 5.125em;
        @media screen and (max-width: 739.9px) {
          margin-bottom: 2.125em;
        }

        p {
          color: #1d1d1b;

          text-align: center;
          font-family: "Kaspersky";
          font-size: 1.5em;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          @media screen and (max-width: 739.9px) {
            font-weight: 600;
            font-size: 1.125em;
            text-transform: uppercase;
            text-align: center;
            color: #1d1d1b;
          }
        }
        span {
          color: #7d39a4;
          // font-family: "Kaspersky";
          // font-size: 1.5em;
          // font-style: normal;
          // font-weight: 700;
          // line-height: normal;
          // text-transform: uppercase;
          // @media screen and (max-width: 739.9px) {
          //     font-size: 1em;
          // }
        }
      }

      &__icon {
        width: 3.25em;
        height: 2.75em;
        @media screen and (max-width: 739.9px) {
          width: 2.25em;
          height: 1.75em;
        }
      }

      &__text {
        position: relative;
        max-width: 37.875em;
        min-width: 606px;
        width: calc(100% - 2em);
        height: fit-content;
        border: 0.25em solid #1d1d1b;
        background-color: #fdf8c8;
        padding: 3em 1.5em;
        border-radius: 2em;
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          width: 92%;
          height: 3em;
          background: linear-gradient(0deg, rgba(253, 248, 200, 1) 0%, rgba(253, 248, 200, 0) 75%);
        }

        @media screen and (max-width: 739.9px) {
          // padding: 0 0.375em 0 1.375em;
          padding: 0 0.375em 0 1em;
          min-width: calc(100% - 1em);
        }
        &-wrapper {
          max-height: 80vh;
          overflow: auto;
          @media screen and (max-width: 739.9px) {
            // max-height: 90vh;
            max-height: 65vh;
          }
        }
        &-content {
          padding: 0 1.5em;
          @media screen and (max-width: 739.9px) {
            padding: 2em 0 0;
          }
          h3 {
            margin-bottom: 0.5em;

            font-weight: 600;
            font-size: 1.125em;
            text-transform: uppercase;
            color: #1d1d1b;
            text-align: center;
            @media screen and (max-width: 739.9px) {
              font-weight: 600;
              font-size: 1.125em;
              text-transform: uppercase;
              color: #1d1d1b;
              text-align: left;
            }
          }
          p {
            margin-bottom: 1em;

            font-weight: 400;
            font-size: 1em;
            line-height: 1.45;
            text-align: center;
            color: #1d1d1b;
            @media screen and (max-width: 739.9px) {
              margin-bottom: 0.5em;
              font-weight: 400;
              font-size: 0.875em;
              line-height: 1.45;
              color: #1d1d1b;
              text-align: left;
            }

            &:last-child {
              margin-bottom: 0;
              @media screen and (max-width: 739.9px) {
                margin-bottom: 2em;
              }
            }
          }
          span {
            color: #7d39a4;
          }
        }
      }

      &__birds-img {
        position: absolute;
        top: 15%;
        opacity: 1;
        visibility: visible;
      }

      &:nth-child(1) {
        background-color: #e6ba64;
      }
      &:nth-child(2) {
        // margin-top: -15%;
        @media screen and (max-width: 739.9px) {
          // margin-top: -70%;
        }
        // height: 50vh;
        // transform: translateY(-25%);
        .screen__content {
          // opacity: 1;
          // flex-direction: column;
          // justify-content: flex-start;
          // align-items: center;
          //    opacity: 0;
        }
      }
      &:nth-child(3) {
        // margin-top: -25%;
        // @media screen and (max-width: 739.9px) {
        //   margin-top: -75%;
        // }

        // height: 50vh;
        // .screen__content {
        //     flex-direction: column;
        //     justify-content: flex-start;
        //     align-items: center;
        // }
      }
      &:nth-child(4) {
        position: relative;
        // top: 0 !important;
        // position: absolute;
        height: 100%;
        // transform: translate3d(0px, 0px, 0px) !important;
      }

      &:not(:first-child) {
        top: 100%;
      }

      &__evening {
        // z-index: -1;
        // transform: translate3d(0px, 0, 0px)!important;
      }
      &__day {
        .screen__content {
          // opacity: 0;
          // position: fixed!important;
          // opacity: 0!important;
          // visibility: hidden!important;
          // transform: translate(0px, 0px) !important;
          // transform: translate3d(0px, 0px, 0px) !important;
        }
        // .content-hidden.visible {
        //   opacity: 1;
        //   visibility: visible;
        // }
        // .content-hidden {
        //   opacity: 1;
        //   visibility: visible;
        // }
        // .visible {
        //   opacity: 1;
        //   visibility: visible;
        // }
        // .red {
        //   opacity: 1!important;
        //   visibility: visible!important;
        // }
      }
      &__night {
        min-height: 44.0625em;
        z-index: -1;
      }

      // animation-duration: 1s;
      // animation-name: hide-bg;
    }

    @keyframes hide-bg {
      0% {
        opacity: 1;
        visibility: visible;
        // background: linear-gradient(76deg, #73f 0%, #d3f 101.05%);
      }

      100% {
        opacity: 0;
        visibility: hidden;
        // background: linear-gradient(34deg, #2ee5c7 47.61%, #5f3 114.66%);
      }
    }

    .screen.active {
      position: fixed;
      opacity: 1;
      visibility: visible;

      // animation-duration: 1s;
      // animation-name: show-bg;
      // .screen__img {
      //     opacity: 1;
      //     visibility: visible;

      //     animation-duration: 1s;
      //     animation-name: show-bg;
      // }
    }

    @keyframes show-bg {
      0% {
        opacity: 0;
        visibility: hidden;
        // background: linear-gradient(76deg, #73f 0%, #d3f 101.05%);
      }

      100% {
        opacity: 1;
        visibility: visible;
        // background: linear-gradient(34deg, #2ee5c7 47.61%, #5f3 114.66%);
      }
    }
  }
  .content-hidden {
    visibility: hidden;
    // display: none;
  }

  .content-hidden.visible {
    visibility: visible;
    // display: flex;
  }
  .controls {
    position: fixed;
    // top: calc(85px + 2.5em);
    top: 2.5em;
    right: 2.5em;
    z-index: 103;
    display: flex;
    // gap: 1.5em;
    gap: 0;

    @media screen and (max-width: 739.9px) {
      gap: 0.75em;
      top: auto;
      bottom: 3.2em;
      left: 50%;
      transform: translateX(-50%);
    }
    &__element {
      cursor: pointer;
      width: 4.25em;
      height: 4em;
      background: transparent;
      border: none;
      // font-size: 0;
      text-indent: -999em;
      @media screen and (max-width: 739.9px) {
        min-width: 2.6563em;
        width: 2.6563em;
        height: 2.5em;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 100%;
        height: auto;
      }

      &-img {
        background-size: cover;

        width: 2.6563em;
        height: 2.5em;
      }

      &-play {
        .controls__element-img {
          background-image: url("../img/play-btn.svg");
        }

        &.active {
          .controls__element-img {
            background-image: url("../img/pause-btn.svg");
          }
        }
      }

      &-sound {
        position: relative;
        .controls__element-img {
          background-image: url("../img/sound-btn.svg");
        }

        &.active {
          .controls__element-img {
            background-image: url("../img/sound-off.svg");
          }
        }

        &.loading {
          .controls__loader {
            display: block;
          }
        }
      }

      &-zoom {
        .controls__element-img {
          background-image: url("../img/zoom-btn.svg");
        }

        &.active {
          .controls__element-img {
            background-image: url("../img/zoom-off.svg");
          }
        }
      }
    }
  }

  .clouds {
    &__img-block {
      &_1 {
        position: absolute;
        top: 19.25em;
        right: -6.5em;

        width: 32.625em;
        height: 11.875em;
        @media screen and (max-width: 739.9px) {
          width: 15.625em;
          height: 5.875em;
        }
        img {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
        }
      }
      &_2 {
        z-index: 2;
        position: absolute;
        top: 9.375em;
        right: -19.4375em;

        width: 26.375em;
        height: 6.3125em;

        @media screen and (max-width: 739.9px) {
          width: 13.375em;
          height: 5.3125em;
          right: -10.4375em;
        }
        img {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
        }
      }
      &_3 {
        position: absolute;
        bottom: 11.875em;
        left: -25.3125em;

        width: 23.875em;
        height: 3.625em;
        @media screen and (max-width: 739.9px) {
          width: 15.875em;
          height: 3.625em;
          position: absolute;
          bottom: 11.875em;
          left: -16.3125em;
        }
        img {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
        }
      }
      &_4 {
        z-index: 2;
        position: absolute;
        bottom: 11.875em;
        left: -11.625em;

        width: 24.25em;
        height: 7.4375em;
        @media screen and (max-width: 739.9px) {
          width: 11.25em;
          height: 4.4375em;
          position: absolute;
          bottom: 11.875em;
          left: -5.625em;
        }
        img {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
        }
      }
      &_sun {
        z-index: 1;
        position: absolute;
        bottom: 11.275em;
        left: 1.6875em;

        width: 8.4375em;
        height: 7.8125em;

        @media screen and (max-width: 739.9px) {
          width: 10.875em;
          height: 3.625em;
          position: absolute;
          bottom: 12.375em;
          left: -2.3125em;
        }
        img {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
        }
      }
      // img {
      //     &:nth-child(1) {
      //         position: absolute;
      //         top: 384px;
      //         right: -57px;

      //         width: 522px;
      //         height: 190px;
      //     }
      //     &:nth-child(2) {
      //         position: absolute;
      //         top: 223px;
      //         right: -311px;

      //         width: 422px;
      //         height: 101px;
      //     }
      //     &:nth-child(3) {
      //         position: absolute;
      //         bottom: 116px;
      //         left: -405px;

      //         width: 382px;
      //         height: 58px;
      //     }
      //     &:nth-child(4) {
      //         position: absolute;
      //         bottom: 114px;
      //         left: -186px;

      //         width: 388px;
      //         height: 119px;
      //     }
      // }
    }
  }

  .bottom {
    position: relative;
    // background-color: #c3a0d8;
    height: auto;
    .item {
      // position: relative;
      // position: fixed;
      // opacity: 0;
      // visibility: hidden;
      // height: calc(100vh - 85px);
      height: 100vh;
      width: 100%;
      // top: 0;
      // left: 0;

      &__bg {
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
      }
      img {
        // width: 100%;
        // height: 100%;
      }

      &__content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }

      &__title {
        margin-bottom: 2.5em;
        width: 46.875em;
        height: 5.9375em;
      }

      &__description {
        margin-bottom: 5.125em;
        p {
          color: #1d1d1b;

          text-align: center;
          font-family: "Kaspersky";
          font-size: 1.5em;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }
        span {
          color: #7d39a4;
          font-family: "Kaspersky";
          font-size: 1.5em;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }
      }

      &__icon {
        width: 3.25em;
        height: 2.75em;
      }

      &__text {
        position: relative;
        max-width: 37.875em;
        width: calc(100% - 2em);
        height: fit-content;
        border: 0.25em solid #1d1d1b;
        background-color: #fdf8c8;
        padding: 3em 4em;
        border-radius: 2em;
        @media screen and (max-width: 739.9px) {
          padding: 3em 2em;
        }
        &-content {
          p {
            margin-bottom: 1em;
            color: #1d1d1b;

            text-align: center;
            font-size: 1em;
            font-style: normal;
            font-weight: 700;
            line-height: 145%; /* 23.2px */
            text-transform: uppercase;

            &:last-child {
              margin-bottom: 0;
            }
          }
          span {
            color: #7d39a4;
          }
        }
      }
    }
  }

  .building {
    position: relative;
    height: auto;
    opacity: 1;
    z-index: 100;
    overflow: hidden;
    &::before {
      content: "";
      z-index: 0;
      position: absolute;
      width: 100%;
      height: 62.5em;
      bottom: 0;
      // background: linear-gradient(180deg, #c2a0d8 22.36%, #854fa8 100%);
      // background: rgb(194, 160, 216);
      // background: linear-gradient(180deg, rgba(194, 160, 216, 0) 0%, rgba(133, 79, 168, 1) 100%);
      background: linear-gradient(0deg, rgba(133, 79, 168, 1) 0%, rgba(133, 79, 168, 0) 80%);
    }

    &__title {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 2em;
      max-width: 90.25em;
      @media screen and (max-width: 739.9px) {
        flex-direction: column;
        gap: 0;
        margin: 0 auto 0.5em;
      }
      h2 {
        color: #fff;

        text-align: center;
        font-family: Verdana;
        font-size: 2.5em;
        font-style: normal;
        font-weight: 700;
        line-height: 135%; /* 3.375em */
        letter-spacing: 0.25em;
        text-transform: uppercase;
        text-shadow:
          -0.0625em 0 #632b6c,
          0 0.0625em #632b6c,
          0.0625em 0 #632b6c,
          0 -0.0625em #632b6c;
        // text-shadow: #FC0 1px 0 10px;
        @media screen and (max-width: 739.9px) {
          font-weight: 700;
          font-size: 1.5em;
          line-height: 1.35;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          text-align: center;
          color: #fff;
        }
      }
    }

    &__notification {
      position: absolute;
      right: 2em;
      @media screen and (max-width: 739.9px) {
        position: relative;
        padding: 1em 0 0.5em;
        right: auto;
      }
      // bottom: 0;
      img {
        width: 12.5625em;
        height: auto;
      }
    }

    .swiper-wrapper {
      // justify-content: center;
      // transform: translate3d(0px, 0px, 0px) !important;
      .swiper-slide.building__person {
        @media screen and (max-width: 739.9px) {
          opacity: 0.5;
          transform: scale(0.8);
        }
      }
      .swiper-slide.building__person.swiper-slide-active {
        @media screen and (max-width: 739.9px) {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    .swiper-button-next {
      right: 0.625em;
      width: 2.5em;
      height: 1.75em;
      @media screen and (max-width: 739.9px) {
        right: 0.75em;
      }
      img {
        width: 2.5em;
        height: 1.75em;
        @media screen and (max-width: 739.9px) {
          width: 1.6875em;
          height: 1.1875em;
        }
      }
    }
    .swiper-button-prev {
      left: 0.625em;
      width: 2.5em;
      height: 1.75em;
      @media screen and (max-width: 739.9px) {
        left: 0.75em;
      }
      img {
        width: 2.5em;
        height: 1.75em;
        @media screen and (max-width: 739.9px) {
          width: 1.6875em;
          height: 1.1875em;
        }
      }
    }

    .swiper-pagination-bullets {
      bottom: 0 !important;
    }
    .swiper-pagination-bullet {
      position: relative;
      margin: 0 0.5em !important;
      width: 0.625em;
      height: 0.625em;
      background: none;
      opacity: 1;

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-image: url("../img/slider-dot.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .swiper-pagination-bullet-active {
      &:before {
        background-image: url("../img/slider-dot-active.svg");
      }
    }

    &__persons {
      position: relative;
      width: 100%;
      // max-width: 82.375em;
      // padding: 6px;
      max-width: 95em;
      // padding: 0.375em 6.75em;

      margin: 0 auto;
      overflow: hidden;
      @media screen and (max-width: 739.9px) {
        flex-direction: column;
        align-items: center;
        overflow: visible !important;
      }

      &-wrapper {
        margin: 0 auto;
        overflow: hidden;
        max-width: 82.375em;
        padding: 0.875em 0.875em 3.125em;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        gap: 1.5em;
      }
    }
    &__person {
      position: relative;
      max-width: 19em;
      height: fit-content;
      padding: 2em;
      border: 0.1875em solid #1d1d1b;
      background: linear-gradient(34deg, #2ee5c7 47.61%, #5f3 114.66%);
      border-radius: 1.875em;
      cursor: pointer;
      transition: all 0.25s ease;

      @media screen and (max-width: 739.9px) {
        // max-width: 14.75em;
        padding: 1.4em 1.75em 1.4em 1.5em;
        min-height: 7.5em;
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        opacity: 1;
        // background: linear-gradient(34deg, #2ee5c7 47.61%, #5f3 114.66%);
        // animation-duration: 1s;
        // animation-name: show-bg-revers;
      }

      @keyframes show-bg-revers {
        0% {
          opacity: 0;
          // background: linear-gradient(76deg, #73f 0%, #d3f 101.05%);
        }

        100% {
          opacity: 1;
          // background: linear-gradient(34deg, #2ee5c7 47.61%, #5f3 114.66%);
        }
      }
      &-content {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        gap: 0.8125em;
      }

      &-img {
        width: 5.0625em;
        height: 5.0625em;
        border-radius: 0.5em;
        @media screen and (max-width: 739.9px) {
          width: 4em;
          height: 4em;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 0.375em;

        &_name {
          color: #138170;

          font-size: 0.875em;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          transition: all 0.25s ease;

          @media screen and (max-width: 739.9px) {
            font-weight: 700;
            font-size: 0.75em;
            text-transform: uppercase;
            color: #138170;
          }
        }

        &_post {
          color: #1d1d1b;

          font-size: 0.75em;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: all 0.25s ease;
          @media screen and (max-width: 739.9px) {
            font-weight: 400;
            font-size: 0.625em;
            color: #1d1d1b;
          }
        }
      }

      &:hover {
        &:before {
          content: "";
          z-index: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          inset: 0;
          opacity: 1;
          background: linear-gradient(76deg, #73f 0%, #d3f 101.05%);
          animation-duration: 1s;
          animation-name: show-bg;
          transition: all 0.5s ease;
        }

        .border {
          width: 3.5em;
          height: 3.5em;
          @media screen and (max-width: 739.9px) {
            width: 2.5em;
            height: 2.5em;
          }
          &:nth-child(1) {
            position: absolute;
            top: -0.775em;
            left: -0.775em;
            @media screen and (max-width: 739.9px) {
              top: -0.75em;
              left: -0.75em;
            }
          }
          &:nth-child(2) {
            position: absolute;
            top: -0.775em;
            right: -0.775em;
            @media screen and (max-width: 739.9px) {
              top: -0.75em;
              right: -0.75em;
            }
          }
          &:nth-child(3) {
            position: absolute;
            bottom: -0.775em;
            right: -0.775em;
            @media screen and (max-width: 739.9px) {
              bottom: -0.75em;
              right: -0.75em;
            }
          }
          &:nth-child(4) {
            position: absolute;
            bottom: -0.775em;
            left: -0.775em;
            @media screen and (max-width: 739.9px) {
              bottom: -0.75em;
              left: -0.75em;
            }
          }
        }

        @keyframes show-bg {
          0% {
            opacity: 0;
            // background: linear-gradient(34deg, #2ee5c7 47.61%, #5f3 114.66%);
          }

          100% {
            opacity: 1;
            // background: linear-gradient(76deg, #73f 0%, #d3f 101.05%);
          }
        }

        .building__person-info_name {
          color: #fff;
        }
        .building__person-info_post {
          color: #fff;
        }
      }
    }

    &__office {
      position: relative;
      z-index: 0;
      margin-top: -14.9375em;
      transition: all 0.3s ease;
      @media screen and (max-width: 739.9px) {
        margin-top: -8em;
      }

      &.left {
        @media screen and (max-width: 739.9px) {
          transform: translateX(25em);
        }
      }
      &.center {
        @media screen and (max-width: 739.9px) {
          transform: translateX(0);
        }
      }

      &.right {
        @media screen and (max-width: 739.9px) {
          transform: translateX(-24em);
        }
      }

      &-img {
        position: relative;
        z-index: 0;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
          // clip-path: inset(40% 30% 40% 30%);
          /* Вырезаем квадрат */

          // filter: brightness(50%);
        }
        @media screen and (max-width: 739.9px) {
          min-width: 75em;
          margin-left: -25.5625em;
        }
      }
      // &-mask {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   background: rgba(0, 0, 0, 0.7);
      //   mask: none;
      //   mask-image: radial-gradient(circle, transparent 40%, black 41%);
      //   -webkit-mask-image: radial-gradient(circle, transparent 40%, black 41%);
      //   pointer-events: none;
      // }
      &-links {
      }
      &-link {
        position: absolute;

        width: 12.5em;
        height: 12.5em;
        cursor: pointer;
        // border: 1px solid red;

        &:nth-child(1) {
          top: 36.75%;
          left: 17%;
        }
        &:nth-child(2) {
          top: 46%;
          left: 44.75%;
        }
        &:nth-child(3) {
          top: 36.75%;
          left: 72.5%;
        }

        @media screen and (max-width: 739.9px) {
          cursor: pointer;
          width: 7.2em;
          height: 8.5em;
          position: absolute;
          top: 45.5%;
          left: 40%;
        }
      }
    }
  }

  .building.hide {
    position: absolute;
    opacity: 0;
    z-index: -99;
    // animation-duration: 1s;
    // animation-name: hide-building;
  }

  // @keyframes hide-building {
  //     0% {
  //         opacity: 1;
  //         transform: scale(1);
  //     }

  //     100% {
  //         opacity: 0;
  //         transform: scale(1.5);
  //     }
  // }

  .borders {
    .border {
      width: 3.1875em;
      height: 3.1875em;
      transition: all 0.25s ease;
      @media screen and (max-width: 739.9px) {
        width: 2.5em;
        height: 2.5em;
      }
      &:nth-child(1) {
        position: absolute;
        top: -0.45em;
        left: -0.45em;
      }
      &:nth-child(2) {
        position: absolute;
        top: -0.45em;
        right: -0.45em;
      }
      &:nth-child(3) {
        position: absolute;
        bottom: -0.45em;
        right: -0.45em;
      }
      &:nth-child(4) {
        position: absolute;
        bottom: -0.45em;
        left: -0.45em;
      }
    }
  }

  .windows {
    z-index: 102;
    position: fixed;
    bottom: 0;
    left: 50%;
    display: flex;
    // display: none;
    align-items: center;
    justify-content: center;

    max-width: 100%;
    width: 100%;
    height: 100%;

    pointer-events: none;
    overflow: hidden;

    transform: translate(-50%, 0);

    &.active {
      pointer-events: initial;
    }

    &.hidden {
      display: none;
    }
    // width: 100%;
    // width: 60.125em;
    // height: 59.1875em;

    @media screen and (max-width: 739.9px) {
      width: 100%;
      // bottom: 25%;
      // height: 31.25em;
    }

    .container {
      max-width: 70em;
    }

    &__wrapper {
      overflow: hidden;

      position: relative;
      // width: 100%;
      // max-width: 60.125em;
      // height: 100%;
      // max-height: 59.1875em;
      padding: 1em 0 0;
      width: 100%;
      max-width: 55em;
      height: 100%;
      max-height: 53.35em;
      margin: 0 auto;
      // background-color: #6b725f;

      @media screen and (max-width: 739.9px) {
        padding: 0;
        height: auto;
      }
    }

    &__item {
      background-color: #6b725f;
    }

    &__images {
      width: 100%;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 0 7.125em;
      // display: none;

      @media screen and (max-width: 739.9px) {
        justify-content: space-between;
        align-items: center;
        gap: 0 0.5em;
      }
    }

    &__images-links {
      position: absolute;
      top: 0;
      .windows__image {
        // width: 26.5em;
        max-width: calc(50% - 3.6em);
        aspect-ratio: 0.9;
        // height: 29.3438em;
        // height: calc(50vh - 42px);

        @media screen and (max-width: 739.9px) {
          width: 11.4em;
          height: 13em;
          max-width: calc(50% - 1.6em);
        }
      }
    }

    &__image {
      // width: 26.5em;
      // height: 29.3438em;

      width: 100%;
      max-width: 26em;
      height: 100%;
      // max-height: 29.3438em;
      max-width: 43.5%;
      max-height: calc(50vh - 2.625em);
      display: flex;

      img {
        object-fit: cover;
        width: 100%;
        object-position: center center;
      }

      cursor: pointer;
      @media screen and (max-width: 739.9px) {
        width: 100%;
        max-width: 11.4em;
        height: 100%;
        max-height: 13em;
      }
    }

    &__bg {
      position: absolute;
      bottom: 0em;
      // left: -0.1875em;
      margin: 0 auto;
      max-width: 60.125em;
      width: 100%;
      max-height: 58.9375em;
      display: flex;
      height: 100%;
      img {
        width: 100%;
      }
      @media screen and (max-width: 739.9px) {
        width: 100%;
      }
      img {
        @media screen and (max-width: 739.9px) {
          object-fit: cover;
          height: 100%;
        }
      }
    }

    .swiper-wrapper {
      // justify-content: center;
      // transform: translate3d(0px, 0px, 0px) !important;
      .swiper-slide.building__person {
        @media screen and (max-width: 739.9px) {
          opacity: 0.5;
          transform: scale(0.8);
        }
      }
      .swiper-slide.building__person.swiper-slide-active {
        @media screen and (max-width: 739.9px) {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    .swiper-button-next {
      right: 13em;
      width: 2.5em;
      height: 1.75em;
      @media screen and (max-width: 739.9px) {
        right: 0.75em;
      }
      img {
        width: 2.5em;
        height: 1.75em;
        @media screen and (max-width: 739.9px) {
          width: 1.6875em;
          height: 1.1875em;
        }
      }
    }
    .swiper-button-prev {
      left: 13em;
      width: 2.5em;
      height: 1.75em;
      @media screen and (max-width: 739.9px) {
        left: 0.75em;
      }
      img {
        width: 2.5em;
        height: 1.75em;
        @media screen and (max-width: 739.9px) {
          width: 1.6875em;
          height: 1.1875em;
        }
      }
    }

    .swiper-pagination-bullets {
      bottom: 1.5em !important;
      @media screen and (max-width: 739.9px) {
        bottom: 20vh !important;
      }
    }
    .swiper-pagination-bullet {
      position: relative;
      margin: 0 0.5em !important;
      width: 1.25em;
      height: 1.25em;
      background: none;
      opacity: 1;

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-image: url("../img/slider-dot.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .swiper-pagination-bullet-active {
      &:before {
        background-image: url("../img/slider-dot-active.svg");
      }
    }
  }

  .windows.show {
    z-index: 0;
    display: flex;
    opacity: 1;
    transform: scale(1);
    animation-duration: 1s;
    animation-name: scale-windows;
  }

  @keyframes scale-windows {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  .slider {
    // z-index: -100;
    // display: none;
    padding: 1.125em 0;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 104;
    background-color: #c2a0d8;

    pointer-events: none;
    opacity: 0;

    @media screen and (max-width: 739.9px) {
      padding: 0 0 3.125em;
    }
    .container {
      // padding-top: 5em;
      // padding-top: 6.25em;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: center;

      max-width: 94.75em;
      margin: 0 auto;

      // max-height: 56.5em;
      max-height: 61.5em;

      height: 100%;
      margin: auto;
      // padding-bottom: 2em;

      @media screen and (max-width: 739.9px) {
        height: auto;
        padding-top: 0;
        display: flex;
        flex-direction: column;
      }
    }
    .modal__head {
      position: relative;
      top: auto;
      margin-bottom: -4em;
      width: fit-content;
      margin-left: auto;
      @media screen and (max-width: 739.9px) {
        position: absolute;
        margin-bottom: 0;
        width: 100%;
        margin-left: 0;
      }
    }
    .swiper {
      padding: 0 0 5em;
      overflow: visible;

      align-items: center;

      height: 100%;
      // max-height: 51.25em;
      max-height: 56.25em;
      @media screen and (max-width: 739.9px) {
        padding: 0.5em 0.5em;
        width: 100%;
        padding: 0;
      }
    }
    .swiper-fade .swiper-slide-active {
      z-index: 2;
    }
    .swiper-button-next {
      right: -3.5em;
      width: 2.25em;
      @media screen and (max-width: 739.9px) {
        right: 1em;
        top: 8em;
      }
      img {
        width: 2.5em;
        height: 1.75em;
        @media screen and (max-width: 739.9px) {
          width: 1.6875em;
          height: 1.1875em;
        }
      }
    }
    .swiper-button-prev {
      left: -3.5em;
      width: 2.25em;
      @media screen and (max-width: 739.9px) {
        left: 1em;
        top: 8em;
      }
      img {
        width: 2.5em;
        height: 1.75em;
        @media screen and (max-width: 739.9px) {
          width: 1.6875em;
          height: 1.1875em;
        }
      }
    }

    .swiper-pagination-bullets {
      // bottom: -3.5em !important;
      bottom: 1.5em !important;
      @media screen and (max-width: 739.9px) {
        display: none;
      }
    }
    .swiper-pagination-bullet {
      position: relative;
      width: 1.25em;
      height: 1.25em;
      background: none;
      opacity: 1;
      &:before {
        content: "";
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-image: url("../img/slider-dot.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .swiper-pagination-bullet-active {
      &:before {
        background-image: url("../img/slider-dot-active.svg");
      }
    }
    &__items {
      display: flex;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      // opacity: 1 !important;
      opacity: 0 !important;
      max-height: 51.25em;
      // padding: 0.5em;
      padding: 4.5em 0.5em 0.5em 0.5em;
      cursor: pointer;
      &:active {
        cursor: grabbing;
      }
      @media screen and (max-width: 739.9px) {
        opacity: 0 !important;
        // opacity: 1;

        overflow: auto;
        height: auto;
        min-height: 100vh;
        max-height: max-content;
      }
    }
    &__item.swiper-slide-active {
      opacity: 1 !important;

      @media screen and (max-width: 739.9px) {
        opacity: 1 !important;
        padding: 0;
      }
    }
    &__content {
      display: flex;
      // gap: 5.375em;
      gap: 4em;
      align-items: center;
      height: 100%;
      // max-height: 31.3125em;
      max-height: 51.25em;

      @media screen and (max-width: 739.9px) {
        gap: 0;
        display: flex;
        flex-direction: column;
        // height: 67vh;
        // height: calc(100% - 16.875em);
        // height: calc(100% - 2em);
        // min-height: max-content;
        height: 100%;
        // height: calc(100% - 2em);
        min-height: 100%;
        padding: 0 0 0.5em;
      }
    }
    &__img {
      position: relative;
      flex-shrink: 0;
      // max-width: 46.5625em;
      max-width: 46em;
      // width: 100%;
      width: fit-content;
      // max-height: 51.1875em;
      height: 100%;
      // max-height: calc(100vh - 12.5em);
      display: flex;
      align-items: center;
      // aspect-ratio: 1;
      aspect-ratio: 35 / 39;
      &::before {
        content: none;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-image: url("../img/glass.png");
        @media screen and (max-width: 739.9px) {
          content: "";
        }
      }

      img {
        width: 100%;
        // height: auto;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
      @media screen and (max-width: 739.9px) {
        height: auto;
        margin-top: -7em;
      }
    }

    .controls {
      z-index: 103;
      gap: 0.75em;
      margin: -1.75em auto 0;
      display: flex;
      align-items: center;
      top: 2.25em;
      left: auto;
      right: 10em;
      transform: translateX(0);

      // gap: 1.5em;
      @media screen and (max-width: 739.9px) {
        content: "";
        position: relative;
        display: flex;
        justify-content: center;
        gap: 0.75em;
        // width: 21.5625em;
        // height: 10.5em;

        top: auto;
        left: auto;
        right: auto;
        transform: translateX(0);
        margin: -1.75em auto 0;
      }

      &::before {
        content: none;
        position: absolute;
        z-index: -1;
        left: 50%;
        top: -5.4375em;

        width: 21.5625em;
        height: 10.5em;

        transform: translateX(-50%);

        background-size: cover;
        background-image: url("../img/controls-bg.svg");
        @media screen and (max-width: 739.9px) {
          content: "";
        }
      }

      &__element {
        cursor: pointer;
        text-indent: -999em;
        background: none;
        border: none;
        min-width: 2.6563em;
        width: 2.6563em;
        height: 2.5em;
        min-height: 2.5em;
        display: flex;
        align-items: center;
        padding: 0;

        @media screen and (max-width: 739.9px) {
          min-width: 2.6563em;
          width: 2.6563em;
          height: 2.5em;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 100%;
          height: auto;
        }

        &-img {
          background-size: cover;

          width: 2.6563em;
          min-width: 2.6563em;
          height: 2.5em;
        }

        &-play {
          .controls__element-img {
            background-image: url("../img/play-btn.svg");
          }

          &.active {
            .controls__element-img {
              background-image: url("../img/pause-btn.svg");
            }
          }
        }

        &-sound {
          position: relative;
          .controls__element-img {
            background-image: url("../img/sound-btn.svg");
          }

          &.active {
            .controls__element-img {
              background-image: url("../img/sound-off.svg");
            }
          }

          &.loading {
            .controls__loader {
              display: block;
            }
          }
        }

        &-zoom {
          .controls__element-img {
            background-image: url("../img/zoom-btn.svg");
          }

          &.active {
            .controls__element-img {
              background-image: url("../img/zoom-off.svg");
            }
          }
        }
      }

      &__loader {
        display: none;
        z-index: 1;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-image: url("../img/control-button-bg.svg");
        background-size: contain;
        background-repeat: no-repeat;

        &:before {
          content: "";
          z-index: 1;
          inset: 0.375em 0.125em 0.125em 0.5em;
          position: absolute;
          width: 1.625em;
          height: 1.625em;
          background-image: url("../img/loading-loader.svg");
          background-size: cover;
          transition: all 0.5s ease;
          animation: rotate 1s linear infinite;
        }
      }

      &__slider-sound {
        // pointer-events: none;
      }

      @keyframes rotate {
        from {
          transform: rotate(-360deg);
        }
      }
    }

    &__info {
      // max-height: 51.1875em;

      position: relative;
      border: 0.25em solid #1d1d1b;
      background-color: #fdf8c8;
      width: 100%;
      // max-width: 42.5em;
      max-width: 45em;
      height: 100%;
      // max-height: calc(100vh - 85px - 15em);
      // max-height: calc(100vh - 14em);
      // height: 780px;
      // aspect-ratio: 1;
      @media screen and (max-width: 739.9px) {
        height: 100%;
        // max-height: calc(100% - 2em);
        // max-height: calc(100% - 12em);
        // max-height: calc(100% - 48vh);
        max-height: calc(100% - 28.8125em);
        width: calc(100% - 2em);
        margin: -3.75em auto 0;

        border-radius: 2em;
      }

      &-wrapper {
        padding: 3em 1.5em 3em 3.75em;
        overflow: auto;
        max-height: 100%;
        @media screen and (max-width: 739.9px) {
          padding: 1.75em 1.35em 1em 1.35em;
          overflow: auto;
          max-height: 100%;
        }
      }

      &-head {
        padding-right: 3em;
        @media screen and (max-width: 739.9px) {
          padding-right: 0;
        }
      }
      &-name {
        margin-bottom: 0.5em;
        color: #138170;

        font-size: 2em;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        @media screen and (max-width: 739.9px) {
          margin-bottom: 0;

          font-weight: 600;
          font-size: 1.125em;
          text-transform: uppercase;
          text-align: center;
          color: #138170;
        }
      }
      &-post {
        margin-bottom: 2em;
        color: #1d1d1b;

        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media screen and (max-width: 739.9px) {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 0.875em;
          line-height: 1.45;
          text-align: center;
          color: #1d1d1b;
        }
      }

      &-main {
        position: relative;
        overflow-y: auto;
        height: calc(100% - 6.625em);
        padding: 1em 2.15em 0 0;
        scrollbar-width: thin;
        scrollbar-color: hsl(0 0% 50%);
        // &:before {
        //     content: "";
        //     position: absolute;
        //     bottom: 0;
        //     width: 100%;
        //     height: 3.125em;
        //     background: linear-gradient(180deg, #d9d9d9 0.63%, #d9d9d9 89.09%, rgba(217, 217, 217, 0) 100%);
        // }

        @media screen and (max-width: 739.9px) {
          padding: 0.75em 0.25em 0 0;
          overflow-y: visible;
          height: 100%;
        }

        &::-webkit-scrollbar {
          width: 0.75em; /* ширина scrollbar */
        }
        &::-webkit-scrollbar-track {
          background: #e589b5; /* цвет дорожки */
        }
        &::-webkit-scrollbar-thumb {
          background-color: #632b6c; /* цвет плашки */
          // border-radius: 20px; /* закругления плашки */
          border: 0.1875em solid #e589b5; /* padding вокруг плашки */
        }

        &_header {
          padding: 0 0.5em 0.25em;
          p {
            font-size: 1em;
            font-weight: 700;
            line-height: 1.4375em;
            letter-spacing: 0em;
            text-align: left;
            color: #1d1d1b;
            @media screen and (max-width: 739.9px) {
              font-weight: 700;
              font-size: 0.875em;
              line-height: 1.45;
              color: #1d1d1b;
            }
          }
          span {
            font-size: 1em;
            font-weight: 700;
            line-height: 1.4375em;
            letter-spacing: 0em;
            text-align: left;
            color: #632b6c;
            @media screen and (max-width: 739.9px) {
              color: #7d39a4;
            }
          }
        }
        &_text {
          padding: 1.5em 0 0;
          p {
            font-size: 1em;
            font-weight: 400;
            line-height: 1.4375em;
            letter-spacing: 0em;
            text-align: left;
            @media screen and (max-width: 739.9px) {
              font-weight: 400;
              font-size: 0.875em;
              line-height: 1.45;
            }
          }
          span {
            font-weight: 700;
            color: #7d39a4;
          }
          p {
            margin-bottom: 1em;
          }
        }
      }
    }

    &__divider {
      z-index: 1;
      background-color: #29ccb1;
      justify-content: center;
      width: 100%;
      height: 0.25em;
      margin: 1em 0 0;
      display: flex;
      position: relative;
      @media screen and (max-width: 739.9px) {
        height: 0.125em;
        margin: 0.5em 0 0;
      }

      &::before {
        content: "";
        position: absolute;
        width: 0.375em;
        height: 0.625em;
        top: -0.25em;
        background-image: url("../img/line-divider.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
      &::after {
        content: "";
        position: absolute;
        width: 0.375em;
        height: 0.375em;
        top: -0.14em;
        background-color: #29ccb1;
      }
    }
  }

  .slider.show {
    z-index: 104;
    background-color: #c2a0d8;

    .slider__img {
      opacity: 1;
      transform: scale(1);
      // animation-duration: 1s;
      // animation-name: scale-img;
    }

    @keyframes scale-img {
      0% {
        // opacity: 0;
        transform: scale(0.5);
      }

      100% {
        // opacity: 1;
        transform: scale(1);
      }
    }
  }

  .modal {
    position: fixed;
    left: 0;
    // top: 85px;
    top: 0;
    right: 0;
    bottom: 0;
    // z-index: 1000;

    @media screen and (max-width: 739.9px) {
      background-image: url("../img/popup-bg.png");
    }
    &__container {
      margin-left: auto;
      margin-right: auto;
    }

    .container {
      margin-left: auto;
      margin-right: auto;
    }

    &__head {
      z-index: 2;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 0;
      width: 100%;
      max-width: 94em;

      padding: 1.5em 0.75em 1em;

      @media screen and (max-width: 739.9px) {
        // max-width: 82%;
        padding: 1em 0.75em;
      }
    }

    &__all {
      display: none;
      cursor: pointer;
      background: none;
      border: none;
      width: 3em;
      height: 2.5em;
      @media screen and (max-width: 739.9px) {
        display: flex;
        width: 2.2em;
        height: 1.7em;
      }
      img {
        width: 100%;
        height: auto;
      }
    }

    &__close {
      position: relative;
      display: flex;
      align-items: center;
      width: 6.5em;
      // width: 2.5em;
      height: 2.5em;
      margin-left: auto;
      // gap: 1em;
      // margin-left: auto;
      // margin-bottom: 1em;
      // margin-right: 1em;

      cursor: pointer;
      background: transparent;
      border: none;
      @media screen and (max-width: 739.9px) {
        margin-left: 0;
        width: 1.7em;
        height: 1.7em;
      }
      span {
        margin-right: 1em;
        font-size: 0.875em;
        font-weight: 700;
        line-height: 1.0625em;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        color: #ffffff;
        @media screen and (max-width: 739.9px) {
          display: none;
        }
      }
      img {
        width: 1.75em;
        height: auto;
      }
    }
  }

  .welldone {
    background-color: #c2a0d8;
    .container {
      display: flex;
      justify-content: center;
    }
    &__content {
      z-index: 1;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      max-width: 37.5em;
    }

    &__title {
      margin-bottom: 2.5em;
      width: 46.875em;
      height: 5.9375em;
      @media screen and (max-width: 739.9px) {
        width: 20.875em;
        height: 0.9375em;
        margin-bottom: 2.5em;
      }
    }

    &__description {
      margin-bottom: 5.125em;
      @media screen and (max-width: 739.9px) {
        margin-bottom: 2.125em;
      }

      p {
        color: #1d1d1b;

        text-align: center;
        font-family: "Kaspersky";
        font-size: 1.5em;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        @media screen and (max-width: 739.9px) {
          font-size: 1em;
        }
      }
      span {
        color: #7d39a4;
        font-family: "Kaspersky";
        font-size: 1.5em;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        @media screen and (max-width: 739.9px) {
          font-size: 1em;
        }
      }
    }

    &__icon {
      width: 3.25em;
      height: 2.75em;
      @media screen and (max-width: 739.9px) {
        width: 2.25em;
        height: 1.75em;
      }
    }

    &__text {
      position: relative;
      max-width: 37.875em;
      width: calc(100% - 2em);
      height: fit-content;
      border: 0.25em solid #1d1d1b;
      background-color: #fdf8c8;
      padding: 3em 4em;
      @media screen and (max-width: 739.9px) {
        padding: 3em 2em;
      }

      &-content {
        p {
          margin-bottom: 1em;
          color: #1d1d1b;

          text-align: center;
          font-size: 1em;
          font-style: normal;
          font-weight: 700;
          line-height: 145%; /* 23.2px */
          text-transform: uppercase;

          &:last-child {
            margin-bottom: 0;
          }
        }
        span {
          color: #7d39a4;
        }
      }
    }
  }

  .welldone.show {
    z-index: 111;
  }

  .employees {
    background-image: none;
    background: #fdf8c8;
    .modal__head {
      padding: 1em 0.75em;
    }
    .modal__close {
      margin-left: auto;
    }

    &__wrapper {
      max-width: 606px;
      margin: 0 auto;
    }

    &__title {
      z-index: 1;
      justify-content: center;
      align-items: center;
      max-width: 90.25em;
      margin: 0 auto 2em;
      display: flex;
      flex-direction: column;
      position: relative;
      @media screen and (max-width: 739.9px) {
      }

      h2 {
        color: #fff;
        text-align: center;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        text-shadow:
          -0.0625em 0 #632b6c,
          0 0.0625em #632b6c,
          0.0625em 0 #632b6c,
          0 -0.0625em #632b6c;
        font-family: Verdana;
        font-size: 2.5em;
        font-style: normal;
        font-weight: 700;
        line-height: 135%;
        @media screen and (max-width: 739.9px) {
          margin-bottom: 0.5em;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          text-align: center;
          color: #fff;
          font-size: 1.5em;
          font-weight: 700;
          line-height: 1.35;
        }
      }
    }

    &__notification {
      @media screen and (max-width: 739.9px) {
        // position: absolute;
        // right: 0;
      }

      img {
        @media screen and (max-width: 739.9px) {
          width: 9.5625em;
          height: auto;
        }
      }
    }

    &__content {
      padding: 2.25em 1em 0;
      @media screen and (max-width: 739.9px) {
      }
    }

    &__items {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1.75em 1em;
      @media screen and (max-width: 739.9px) {
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 6.8125em;
      @media screen and (max-width: 739.9px) {
      }

      &-img {
        width: 4.5em;
        height: 4.5em;
        margin-bottom: 0.5em;
        @media screen and (max-width: 739.9px) {
        }
      }

      &-info {
        &_name {
          margin-bottom: 0.25em;
          font-weight: 700;
          font-size: 0.75em;
          text-transform: uppercase;
          text-align: center;
          color: #138170;
          @media screen and (max-width: 739.9px) {
          }
        }

        &_post {
          font-weight: 400;
          font-size: 0.625em;
          text-align: center;
          color: #1d1d1b;
          @media screen and (max-width: 739.9px) {
          }
        }
      }
    }
  }

  .employees.modal.show {
    z-index: 111;
  }
}

.pixel.zoom {
  .screens {
    .screen {
      &__title {
        width: 51.875em;
      }
      &__description {
        p {
          font-size: 1.85em;
        }
      }

      &__text {
        max-width: 51em;
        &-content {
          h3 {
            font-size: 1.75em;
          }

          p {
            font-size: 1.25em;
          }
        }
      }
    }
  }

  .building {
    &__title {
      h2 {
        font-size: 3em;
      }
    }
    &__notification img {
      width: 14.5625em;
      height: 5.5em;
    }
    &__persons {
      min-height: 12.5em;
    }
    &__person {
      min-height: 8.5em;
      height: 100%;
      padding: 1.5em 1.5em 1.5em 2em;
      display: flex;
      align-items: center;

      &-img {
        width: 4.7em;
        height: 4.7em;
      }

      &-info_name {
        font-size: 1em;
      }
      &-info_post {
        font-size: 0.75em;
      }

      &__office {
        &-img {
          // margin-left: -27em;
        }

        &-link {
          // left: 33%;
        }
      }
    }
  }

  .slider {
    &__info {
      &-wrapper {
        padding: 3em 0em 3em 3.5em;
      }

      &-name {
        font-weight: 600;
        font-size: 1.75em;
        text-transform: uppercase;
        // text-align: center;
        color: #138170;
      }

      &-post {
        font-weight: 400;
        font-size: 20px;
        line-height: 1.45;
        // text-align: center;
        color: #1d1d1b;
      }

      &-main {
        &_header {
          padding: 0 0 0.25em;

          font-weight: 700;
          font-size: 20px;
          line-height: 1.45;
          color: #1d1d1b;
        }

        &_text {
          font-weight: 400;
          font-size: 20px;
          line-height: 1.45;
          color: #1d1d1b;
        }
      }
    }
  }

  .windows {
    // &__wrapper {
    //   position: relative;
    //   width: 100%;
    //   max-width: 60.125em;
    //   height: 100%;
    //   max-height: 59.1875em;
    //   margin: 0 auto;
    //   background-color: #6b725f;
    // }
    // &__images {
    //   z-index: 1;
    //   display: flex;
    //   flex-wrap: wrap;
    //   align-items: center;
    //   justify-content: center;
    //   gap: 0 5.125em;

    //   @media screen and (max-width: 739.9px) {
    //     justify-content: space-between;
    //     align-items: center;
    //     gap: 0 0.5em;
    //   }
    // }

    // &__images-links {
    //   position: absolute;
    //   top: 0;
    //   .windows__image {
    //     width: 19.1em;
    //     height: 21.3438em;
    //     @media screen and (max-width: 739.9px) {
    //       width: 11.4em;
    //       height: 13em;
    //     }
    //   }
    // }

    // &__image {
    //   // width: 26.5em;
    //   // height: 29.3438em;

    //   width: 100%;
    //   max-width: 19.1em;
    //   height: 100%;
    //   max-height: 21.3438em;
    //   cursor: pointer;
    //   @media screen and (max-width: 739.9px) {
    //     width: 100%;
    //     max-width: 11.4em;
    //     height: 100%;
    //     max-height: 13em;
    //   }
    // }

    // &__bg {
    //   position: absolute;
    //   bottom: 0em;
    //   // left: -0.1875em;
    //   margin: 0 auto;
    //   max-width: 60.125em;
    //   width: 100%;
    //   max-height: 58.9375em;
    //   height: 100%;
    //   @media screen and (max-width: 739.9px) {
    //     width: 100%;
    //   }
    //   img {
    //     @media screen and (max-width: 739.9px) {
    //       object-fit: cover;
    //       height: 100%;
    //     }
    //   }
    // }
  }

  // .borders {
  //   .border {
  //     width: 3.75em;
  //     height: 3.75em;
  //     transition: all 0.25s ease;
  //     &:nth-child(1) {
  //       position: absolute;
  //       top: -0.45em;
  //       left: -0.4em;
  //       @media screen and (max-width: 739.9px) {
  //         top: -0.45em;
  //         left: -0.45em;
  //       }
  //     }
  //     &:nth-child(2) {
  //       position: absolute;
  //       top: -0.5em;
  //       right: -1.35em;
  //       @media screen and (max-width: 739.9px) {
  //         top: -0.45em;
  //         right: -1.1em;
  //       }
  //     }
  //     &:nth-child(3) {
  //       position: absolute;
  //       bottom: -1.15em;
  //       right: -1.15em;
  //       @media screen and (max-width: 739.9px) {
  //         bottom: -0.95em;
  //         right: -0.95em;
  //       }
  //     }
  //     &:nth-child(4) {
  //       position: absolute;
  //       bottom: -1.15em;
  //       left: -0.4em;
  //       @media screen and (max-width: 739.9px) {
  //         bottom: -0.95em;
  //         left: -0.45em;
  //       }
  //     }
  //   }
  // }

  @media screen and (max-width: 739.9px) {
    .screens {
      .screen {
        &__title {
          width: 24.5em;
        }
        &__description {
          p {
            font-size: 1.5em;
          }
        }

        &__text-content {
          h3 {
            font-size: 1.75em;
          }

          p {
            font-size: 1.25em;
          }
        }
      }
    }

    .building {
      &__title {
        h2 {
          font-size: 2em;
        }
      }
      &__notification img {
        width: 14.5625em;
        height: 5.5em;
      }
      &__persons {
        min-height: 12.5em;
      }
      &__person {
        // padding: 2em 1.0625em 2em 3em;
        // min-height: 7.5em;
        // padding: 1.2em 1.25em;
        min-height: 9.5em;
        padding: 1.5em 1.25em;

        &-img {
          width: 4.7em;
          height: 4.7em;
        }

        &-info_name {
          font-size: 1em;
        }
        &-info_post {
          font-size: 0.75em;
        }

        &__office {
          &-img {
            // margin-left: -27em;
          }

          &-link {
            // left: 33%;
          }
        }
      }
    }

    .windows__image {
      // width: 100%;
      // max-width: 10.25em;
      // height: 100%;
      // max-height: 13em;
    }

    .slider {
      &__info {
        &-wrapper {
          padding: 1.75em 1.35em 1em;
        }

        &-name {
          font-weight: 600;
          font-size: 1.75em;
          text-transform: uppercase;
          text-align: center;
          color: #138170;
        }

        &-post {
          font-weight: 400;
          font-size: 1.25em;
          line-height: 1.45;
          text-align: center;
          color: #1d1d1b;
        }

        &-main {
          &_header {
            padding: 0 0 0.25em;

            font-weight: 700;
            font-size: 1.25em;
            line-height: 1.45;
            color: #1d1d1b;
          }

          &_text {
            font-weight: 400;
            font-size: 1.25em;
            line-height: 1.45;
            color: #1d1d1b;
          }
        }
      }
    }

    .modal {
      &__all {
        width: 2.625em;
        img {
          width: 100%;
          height: auto;
        }
      }

      &__close {
        width: 2em;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
